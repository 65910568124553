<template>
  <el-container>
    <el-aside width="200px">
      <el-menu
        class="el-menu-vertical-demo"
        background-color="#545c64"
        text-color="#fff"
        active-text-color="#ffd04b"
        :default-openeds="defaultopen"
        :default-active="$route.path"
        router
      >
      <!------一级菜单------->
        <el-submenu v-for="item in menudata" :key="item.name" index="1">
          <!------一级菜单的模板区域------->
          <template slot="title">
            <!------图标--------------->
            <i :class="item.icon"></i>
            <!------文本--------------->
            <span>{{item.name}}</span>
          </template>
          <el-menu-item v-for="i in item.children" :key="i.name" :index="'/management/'+i.path">
              <template slot="title">
            <!------图标--------------->
            <i :class="i.icon"></i>
            <!------文本--------------->
            <span>{{i.name}}</span>
          </template>
          </el-menu-item>
        </el-submenu>
        
      </el-menu>
      <div class="btn"><el-button type="primary" @click="logout">退出登录</el-button></div>
      
    </el-aside>
    <el-main><router-view/></el-main>
  </el-container>
</template>
<script>
export default {
  data(){
    return{
      menudata:[{
        name:'后台管理系统',
        icon:'el-icon-s-grid',
        children:[
          {
            name:'基本信息',
            icon:'el-icon-s-ticket',
            path:'companyinfo'
          },
          {
            name:'联系信息',
            icon:'el-icon-s-cooperation',
            path:'contact'
          },
          {
            name:'产品分类',
            icon:'el-icon-s-claim',
            path:'category'
          },
          {
            name:'产品信息',
            icon:'el-icon-menu',
            path:'productmanage'
          },
          {
            name:'修改密码',
            icon:'el-icon-s-tools',
            path:'changepwd'
          }
        ]
      }],
      defaultopen:['1']
    }
  },
  created() {

    //检查token是否有效
    const tokenstr=localStorage.getItem('token')
    this.$http.post('user/CheckToken?token='+tokenstr).then(res=>{
    if(res.data.code!=200){
      window.console.log(res.data)
      this.$message.error(res.data.errmsg)
      this.$router.push('/login')
    }else{
      //把userid保存到store里面
      this.$store.commit('updateUserid',res.data.data.UserId)
    }
  }).catch(err=>{
    window.console.log(err.data)
    this.$router.push('/login')
  })
  
  },
  methods:{
    logout(){
      window.localStorage.clear();
      this.$router.push('/home')
    }
  }
}
</script>
<style scoped>
.el-menu{
    border-right: none;
}
.btn{
  width: 100%;
  text-align: center;
  margin-top:20px;
}

</style>